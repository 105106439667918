/*
 * @Author: your name
 * @Date: 2021-01-04 08:50:00
 * @LastEditTime: 2021-04-21 15:22:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shiptal-frontend\src\assets\axios\index.js
 */
import axios from 'axios'
import qs from 'qs'
import router from './../router'
const tokenErrorArr = [50001, 50002, 50003, 50004]
// axios默认配置
axios.defaults.timeout = 20000 // 超时时间

// 整理数据
axios.defaults.transformRequest = function(data) {
  return data
}

// 路由请求拦截
// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (config.uploadFile) {
      config.headers['Content-Type'] = 'multipart/form-data'
    } else if (config.wwwForm) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.data = qs.stringify(config.data)
    } else {
      config.headers['Content-Type'] = config.wwwForm ? 'application/x-www-form-urlencoded' : 'application/json;charset=UTF-8'
      config.data = JSON.stringify(config.data)
    }
    if (config.method === 'get' && !config.stripe) {
      // config.params.ts = Math.floor(Math.random() * 10000) + new Date().getTime()
      var time = Date.parse(new Date()).toString() // 获取到毫秒的时间戳，精确到毫秒
      time = time.substr(0, 10) // 精确到秒
      config.params.ts = time
    }
    const headAuthorization = config.headers.Authorization
    const token = localStorage.getItem('loginInfo') && JSON.parse(localStorage.getItem('loginInfo')).i
    config.headers.Authorization = headAuthorization || token || ''
    return config
  },
  error => {
    return Promise.reject(error.response)
  })

// 路由响应拦截
// http response 拦截器
axios.interceptors.response.use(
  response => {
    if (tokenErrorArr.indexOf(response.data.code) > -1) {
      router.push({
        path: '/login'
      })
      // 返回 错误代码-1 清除ticket信息并跳转到登录页面
      return
    } else {
      return response
    }
  },
  error => {
    return Promise.reject(error.response) // 返回接口返回的错误信息
  })
export default axios
