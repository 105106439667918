/*
 * @Author: your name
 * @Date: 2021-04-21 10:33:06
 * @LastEditTime: 2021-04-21 10:39:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\service\user.js
 */

import axios from '../utils/axios'

// 获取积分各类别数量
export function pointInfo(params) {
  return axios.get('/api/points/rules', { params })
}
// 获取积分明细
export function pointDetailed(params) {
  return axios.get('/api/points/flow', { params })
}
// 获取分享信息
// 1 PointsShopping                       // 购物奖励
// 2 PointsComment                        // 评论奖励
// 3 PointInvitation                      // 邀请奖励
// 4 PointLike                            // 点赞奖励
// 5 PointShareRegister                   // 分享注册
// 6 PointShareOrder                      // 分享下单
// 7 PointShareClick                      // 分享点击
// 8 PointBuyRegister                     // 跟买
// 9 PointBuyOrder                        // 跟买
// 10 PointBuyClick                        // 跟买
export function shareInfo(params) {
  return axios.get('/api/share/link', { params })
}
// 获取积分总数
export function getTotalPoint(params) {
  return axios.get('/api/points/get', { params })
}
// 获取分享注册的统计情况
export function getSharePoint(params) {
  return axios.get('/api/points/srcpoints', { params })
}
// 获取分享跟买订单商品
export function getBuyOrderGoods(params) {
  return axios.get('/api/share/user', { params })
}
// 获取跟买记录
export function getBuyOrderList(params) {
  return axios.get('/api/share/record', { params })
}
// 获取积分比例
export function getPoints(params) {
  return axios.get('/api/point/pay/number/get', { params })
}
// 分享获取地址
export function getShareAddress(params) {
  return axios.get('/api/share/genfbshare', { params })
}
