<!--
 * @Author: your name
 * @Date: 2021-04-27 16:12:22
 * @LastEditTime: 2021-04-27 17:35:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yaaami-frontend-user\src\components\NavBar.vue
-->

<template>
  <div class="header_bar">
    <van-nav-bar
      :title="headTitle"
      @click-right="onClickRight"
      @click-left="onClickLeft"
    >
      <template v-if="showMenu" #left>
        <van-icon name="wap-nav" size="26" />
      </template>
      <template v-if="routePath == '/home'" #right>
        <van-icon name="shopping-cart-o" :badge="count" size="26" />
      </template>
    </van-nav-bar>
    <!--  eslint-disable-next-line vue/no-v-model-argument -->
    <van-popup v-model:show="leftMenu" position="left" :style="{ height: '100%', width: '50%' }">
      <div class="left_menu">
        <div class="user_icon">
          <div class="icon">
            <img src="./../assets/img/user.png" alt="">
          </div>
        </div>
        <div v-if="isLogin" class="handle_list">
          <van-cell is-link url="/home">
            Home
          </van-cell>
          <van-cell is-link url="/mine">
            My Account
          </van-cell>
          <van-cell is-link url="/cart">
            My Cart
          </van-cell>
          <van-cell is-link url="/order">
            My Orders
          </van-cell>
          <van-cell is-link url="/pointsList">
            Yaaami Points
          </van-cell>
          <van-cell is-link url="/messageList">
            Message
          </van-cell>
          <van-cell is-link url="/contactUs">
            Contact us
          </van-cell>
        </div>
        <div v-else class="handle_list">
          <van-cell is-link url="/login">
            Login
          </van-cell>
          <van-cell is-link url="/contactUs">
            Contact us
          </van-cell>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { onMounted, computed, reactive, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getLocal } from '@/common/js/utils'

export default {
  props: {
    headTitle: {
      type: String,
      default: 'Yaaami～'
    },
    showMenu: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      leftMenu: false,
      isLogin: false
    })
    onMounted(() => {
      const userId = getLocal('userId')
      if (userId) {
        state.isLogin = true
        // console.log('headerbar----onMounted--updateCart')
        // 没意义吧？？服了
        // return;
        store.dispatch('updateCart')
      }
    })
    const count = computed(() => {
      return store.state.cartCount
    })
    const routePath = computed(() => {
      return route.path
    })
    const onClickRight = () => {
      router.push({ path: '/cart' })
    }
    const onClickLeft = () => {
      if (props.showMenu) {
        state.leftMenu = true
      }
    }
    return {
      ...toRefs(state),
      count,
      routePath,
      onClickRight,
      onClickLeft
    }
  }
}
</script>

<style lang="less">
  @import '../common/style/mixin';
  .header_bar {
    .van-nav-bar__title {
      max-width: 90%;
      font-size: 0.4rem;
      .main_title {
        span {
          color: @primary;
          font-weight: bold;
        }
      }
    }
    .left_menu {
      height: 100vh;
      width: 100%;
      background: #fff;
    }
    .user_icon {
      width: 100%;
      height:20vh;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .icon {
        width: 1.8rem;
        height: 1.8rem;
        background: #e5e5e5d8;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
          width: 45%;
        }
      }
      .user_name {
        font-size: 0.3733rem;
      }
    }
  }
</style>
