/*
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-21 11:38:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'lib-flexible'
import { Locale } from 'vant'
import moment from 'moment'

// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US'
Locale.use('en-US', enUS)
const app = createApp(App)
app.use(store)
app.use(router)
app.use(Vant)
app.use(Locale)
app.mount('#app')
// 全局过滤器
app.config.globalProperties.$filters = {
  priceHandle(price) {
    if (price) {
      return Number(price / 100).toFixed(2)
    } else {
      return 0.00
    }
  },
  timeFilter(time) {
    if (time) {
      return moment(time).format('MM/DD HH:mm:ss')
    } else {
      return ''
    }
  },
  saleTimeFilter(time) {
    if (time) {
      return time.split(' ')[0]
    } else {
      return ''
    }
  },
  showTimeFilter(time) {
    if (time) {
      return moment(time).format('MM/DD')
    } else {
      return ''
    }
  },
  setWeekFilter(date) {
    var fDate = moment(date).format('YYYY/MM/DD')
    const datelist = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return datelist[new Date(fDate).getDay()]
  }
}
app.config.globalProperties.gradientColor = 'linear-gradient(to right, #78a355, #1d953f)'
app.config.globalProperties.primaryColor = '#1d953f'
app.config.globalProperties.tagColorList = ['#806d9e', '#1e9eb3', '#bacf65', '#eea2a4', '#fbc82f', '#951c48']
