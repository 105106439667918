/*
 * @Author: your name
 * @Date: 2021-04-21 10:33:06
 * @LastEditTime: 2021-04-21 10:39:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\service\user.js
 */

import axios from '../utils/axios'

// 获取购物车列表
export function getCart(params) {
  return axios.get('/api/cart/get', { params })
}
export function addCart(params) {
  return axios.post('/api/cart/add', params)
}
export function updateCart(params) {
  return axios.post('/api/cart/update', params)
}
export function delCart(params) {
  return axios.post('/api/cart/del', params)
}
