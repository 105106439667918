<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2024-05-10 11:46:36
 * @LastEditors: wangweidong wangweidong@hosecloud.com
 * @Description: In User Settings Edit
 * @FilePath: /yaaami-frontend-user/src/views/Home.vue
-->
<template>
  <div class="home">
    <header-bar :show-menu="true" />
    <div id="content" class="content">
      <div class="notice_info">
        <!-- <div class="home_title">
          Notice
        </div> -->
        <div class="notice_list">
          <p v-for="(item, index) in informList" :key="index">
            {{ item.name }}
            <a
              v-if="item.link"
              target="_blank"
              class="go_group"
              :href="item.link"
            >Click here</a>
          </p>
        </div>
      </div>
      <div v-if="orderList.length > 0" class="new_orders">
        <div class="home_title">
          Activities
        </div>
        <div v-if="orderList.length < 4">
          <ul class="order_list">
            <li v-for="(item, index) in orderList" :key="index">
              {{ item.user_name }} bought {{ item.product_name }} *
              {{ item.num }}
            </li>
          </ul>
        </div>
        <div v-else id="review_box" class="order_scroll">
          <ul id="orderUl" class="order_list">
            <li v-for="(item, index) in orderList" :key="index">
              {{ item.user_name }} bought {{ item.product_name }} *
              {{ item.num }}
            </li>
          </ul>
        </div>
      </div>
      <div class="goods-container">
        <!-- 配送日期列表 -->
        <div class="week_ul">
          <div class="week_date">
            <div
              v-for="(item, index) in saleTimeList"
              :key="index"
              class="week_item"
              :class="deliveryDate == item.date ? 'check_date' : ''"
              @click="addConfirm(item, 1)"
            >
              <span>{{ item.date }}</span>
              <p>{{ item.week }}</p>
            </div>
          </div>
        </div>
        <!-- 商品列表 -->
        <div v-if="allDateGoodList.length > 0" class="good-list-scroll">
          <div class="goods_list">
            <div
              v-for="(item, i) in allDateGoodList"
              :key="'eleGoodsList' + i"
              class="goods_item_wrap"
            >
              <!--给每天的第一个 前面 增加日期回显-->
              <div
                v-if="i === 0 || (i > 0 && allDateGoodList[i - 1].sale_date != item.sale_date)"
                :id="item.anchorId"
                class="list_first_showdate"
              >
                {{ item.sale_date }}
                {{ item.sale_week }}
              </div>
              <div class="goods_item">
                <div
                  class="left"
                  @click="goToDetail(item.id, item.product_stock_id)"
                >
                  <img :src="item.pictures" alt="" srcset="">
                  <div
                    v-if="
                      item.original_price &&
                        item.original_price > item.price
                    "
                    class="dis_count"
                  >
                    {{
                      (
                        ((item.original_price - item.price) /
                          item.original_price) *
                        100
                      ).toFixed(0)
                    }}% off
                  </div>
                </div>
                <div class="right">
                  <p class="name">
                    {{ item.name }}
                    <span
                      v-if="item.used_storage > 0"
                      class="kucun"
                      :class="
                        item.used_storage < 10 ? 'reduce_storage' : ''
                      "
                    >{{ item.used_storage }} Left</span>
                    <span v-else class="no_storage" />
                  </p>
                  <div
                    v-for="(tag, tagIndex) in item.tag_data"
                    :key="'tag_data' + tagIndex"
                    class="tagBox"
                  >
                    <van-tag plain>
                      {{ tag }}
                    </van-tag>
                  </div>
                  <!-- <span class="des">{{ item.desc }}</span> -->
                  <div class="all_price">
                    <div>
                      <span
                        class="price"
                      >${{ $filters.priceHandle(item.price) }}</span>
                      <span
                        v-if="
                          item.original_price &&
                            item.original_price > item.price
                        "
                        class="o_price"
                      >${{
                        $filters.priceHandle(item.original_price)
                      }}</span>
                    </div>
                    <div v-if="item.used_storage > 0" class="handle">
                      <van-stepper
                        v-model.number="item.num"
                        theme="round"
                        disable-input
                        :class="item.num > 0 ? '' : 'no_minus'"
                        :min="0"
                        @plus="plusChange(item, index)"
                        @minus="minusChange(item, index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="van-list__finished-text" />
    </div>
    <!-- 底部导航 -->
    <nav-bar />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  reactive,
  onMounted,
  onActivated,
  onDeactivated,
  toRefs,
  nextTick,
  computed
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Toast, Notify, Dialog } from 'vant'
import navBar from '@/components/NavBar'
import HeaderBar from '@/components/HeaderBar'
import {
  getNotice,
  getNewOrderList,
  getGoodsList,
  getMoreGoodsList,
  saleDate,
  categoryList,
  cateProductList,
  allDateProductList
} from '@/service/product'
import { addCart, updateCart, delCart } from '@/service/cart'
import { getCurrentPosition, userInfo } from '@/service/user'
import {
  getLocal,
  setLocal,
  toWeek,
  setLocalCart,
  delLocalCart,
  handleLocalCart
} from '@/common/js/utils'
import { useStore } from 'vuex'
import moment from 'moment'

const weekDay = {
  1: '周一',
  2: '周二',
  3: '周三',
  4: '周四',
  5: '周五',
  6: '周六',
  7: '周日'
}

export default {
  name: 'Home',
  components: {
    navBar,
    HeaderBar
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      bannerList: [],
      informList: [],
      orderList: [],
      tagsList: [],
      currentPosition: {
        lat: '',
        lng: ''
      },
      cartObj: {},
      pointList: [],
      pointValue: '',
      orderTimer: null,
      sale_time: '',
      deliveryDate: '',
      deliveryWeek: '',
      routeUserId: '',
      chooseDateShow: false,
      saleTimeList: [],
      cateActive: 0,
      cateGoodsList: [],
      allDateGoodList: [],
      lastRoute: null
    })
    const count = computed(() => {
      return store.state.cartCount
    })
    onMounted(async() => {
      state.lastRoute = 'init'
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      state.routeUserId = route.query.user_id
      if (state.routeUserId) {
        getUserSession()
      }
      await store.dispatch('getPointList')
      state.pointList = store.state.pointList || []
      state.pointList.filter((v) => {
        if (v.user_points_type === 3) {
          state.pointValue = v.count
        }
      })
      // getGoodsListAll()
      getInformList()
      getGoogleLocation()
      dateList()
    })
    onActivated(async() => {
      if (!state.lastRoute) {
        updateCartFun()
      }
    })
    onDeactivated(async() => {
      state.lastRoute = null
      clearInterval(state.orderTimer)
    })

    // 根据商品库存ID去购物车中查找对应商品的数量
    const getCartNum = (productStockId) => {
      for (const date in state.cartObj) {
        const productMap = state.cartObj[date].productMap
        if (productMap[productStockId]) {
          return Number(productMap[productStockId])
        }
      }
      return 0
    }

    // 根据购物车中的商品数量更新商品列表
    const updateCartFun = async() => {
      const userId = getLocal('userId')
      if (userId) {
        await store.dispatch('updateCart')
        state.cartObj = store.state.cartGoods
      } else {
        store.dispatch('updateLocalCart')
        state.cartObj = store.state.cartGoods
      }
      // state.cateGoodsList.forEach((item, index) => {
      //   item.goodsList.forEach((ele) => {
      //     if (state.cartObj[ele.product_stock_id]) {
      //       ele.num = state.cartObj[ele.product_stock_id]
      //     } else {
      //       ele.num = 0
      //     }
      //   })
      // })
      /**
       * 遍历 allDateGoodList 中的每个商品
       * 并根据 cartObj 中的 productMap 更新每个商品的 num 属性。
       * 如果在 cartObj 中找不到对应的商品，则将 num 设置为 0
       */
      state.allDateGoodList.forEach((item) => {
        item.num = getCartNum(item.product_stock_id)
      })
    }
    const addConfirm = (item) => {
      state.sale_time = item.sale_time
      state.deliveryDate = moment(item.sale_time).format('MM/DD')
      // 商品列表的第一项 即为滚动查看的目标  所以下边 加了 下标'0'
      const targetAnchorId = 'anchorId' + item.sale_time + 'first'
      console.log('targetAnchorId', targetAnchorId)
      const targetElement = document.getElementById(targetAnchorId)
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
      // 选择日期 则 进行也滚动 到当前日期的商品即可
      // return;
      // getCateProductList();
    }

    // 获取售卖时间 同时赋值第一个为默认选中项后 拉取商品列表数据
    const dateList = async() => {
      try {
        const res = await saleDate({})
        if (res.data.code) {
          Notify({ type: 'danger', message: res.data.message })
          return
        }
        var arr = res.data.data
        if (arr && arr.length) {
          const list = []
          arr.forEach((element, index) => {
            var oldDate = element
            element = element.replace(/-/g, '/')
            var item = {
              date: moment(oldDate).format('MM/DD'),
              week: toWeek(element),
              sale_time: oldDate
            }
            list.push(item)
            if (index === 0) {
              state.deliveryDate = moment(oldDate).format('MM/DD')
              state.sale_time = oldDate
            }
          })
          state.saleTimeList = list
          // getCateList(); // 暂时注释掉，要展示全部商品
          getAllDateGoods()
        }
      } catch (err) {
        Notify({ type: 'danger', message: 'Server error' })
      }
    }
    const onClickLeft = () => {
      state.chooseDateShow = true
    }
    const referFriend = () => {
      router.push({ path: '/registerShare' })
    }
    // 获取全部配送日期的商品列表
    const getAllDateGoods = async() => {
      try {
        Toast.loading({
          message: 'loading...',
          forbidClick: true
        })
        const res = await allDateProductList({})
        if (res.data.code) {
          Notify({ type: 'danger', message: res.data.message })
          return
        }
        const dataList = res.data.data
        // 按照日期降序排序
        dataList.sort((a, b) => new Date(a.new_sale_time) - new Date(b.new_sale_time))
        // 为每个商品添加锚点id
        const dateTracker = {}
        dataList.forEach((item, index) => {
          const date = item.new_sale_time
          if (!dateTracker[date]) {
            dateTracker[date] = 0
            item.anchorId = `anchorId${date}first`
          } else {
            item.anchorId = `anchorId${date}${dateTracker[date]}`
          }
          dateTracker[date]++
        })
        // console.log('goods', dataList)
        // 获取购物车数据
        const userId = getLocal('userId')
        if (userId) {
          await store.dispatch('updateCart')
          state.cartObj = store.state.cartGoods
        } else {
          store.dispatch('updateLocalCart')
          state.cartObj = store.state.cartGoods
        }
        // 处理商品数据
        dataList.forEach((item) => {
          item.sale_week = item.new_sale_time ? weekDay[moment(item.new_sale_time).day()] : ''
          item.sale_date = item.new_sale_time ? moment(item.new_sale_time).format('YYYY/MM/DD') : ''
          item.num = getCartNum(item.product_stock_id)
        })
        state.allDateGoodList = dataList
      } catch (e) {
        console.log('e', e)
      } finally {
        Toast.clear()
      }
    }

    // 获取商品分类列表
    const getCateList = async() => {
      try {
        const res = await categoryList({ user_id: state.routeUserId })
        if (res.data.code) {
          Notify({ type: 'danger', message: res.data.message })
        } else {
          const dataList = res.data.data
          const list = []
          dataList.forEach((item, index) => {
            const obj = {}
            obj.id = item.id
            obj.name = item.name ? item.name.en : ''
            list.push(obj)
          })
          state.cateGoodsList = list
          getCateProductList()
        }
      } catch (err) {
        Notify({ type: 'danger', message: 'Server error' })
      }
    }
    // 获取分好类的商品列表
    const getCateProductList = async() => {
      try {
        Toast.loading({
          message: 'loading...',
          forbidClick: true
        })
        // 改为获取 所有的 日期数据
        const res = await cateProductList({ sale_time: '' })
        // const res = await cateProductList({ sale_time: state.sale_time })
        if (res.data.code) {
          Toast.clear()
          Notify({ type: 'danger', message: res.data.message })
        } else {
          Toast.clear()
          const userId = getLocal('userId')
          if (userId) {
            await store.dispatch('updateCart')
            state.cartObj = store.state.cartGoods
          } else {
            store.dispatch('updateLocalCart')
            state.cartObj = store.state.cartGoods
          }
          const dataList = res.data.data
          const timeNum = {}
          for (let i = 0; i < state.saleTimeList.length; i++) {
            const time = state.saleTimeList[i].sale_time
            timeNum[time] = 0
            for (const dataListKey in dataList) {
              const dataListArr = dataList[dataListKey]
              for (let j = 0; j < dataListArr.length; j++) {
                if (dataListArr[j].new_sale_time == time) {
                  if (timeNum[time] == 0) {
                    dataList[dataListKey][j]['anchorId'] =
                      'anchorId' +
                      dataList[dataListKey][j].new_sale_time +
                      'first'
                  } else {
                    dataList[dataListKey][j]['anchorId'] =
                      'anchorId' + dataList[dataListKey][j].new_sale_time + j
                  }
                  timeNum[time]++
                }
              }
            }
          }
          state.cateGoodsList.forEach((item) => {
            item.goodsList = []
          })
          state.cateGoodsList.forEach((item, index) => {
            for (var i in dataList) {
              if (i - 0 === item.id) {
                item.goodsList = dataList[i]
                item.goodsList.forEach((item) => {
                  item.sale_week = item.new_sale_time
                    ? weekDay[moment(item.new_sale_time).day()]
                    : ''
                  item.sale_date = item.new_sale_time
                    ? moment(item.new_sale_time).format('MM/DD')
                    : ''
                  if (state.cartObj[item.product_stock_id]) {
                    item.num = state.cartObj[item.product_stock_id]
                  } else {
                    item.num = 0
                  }
                })
                /* item.goodsList.forEach((item2b) => {
                 if (state.cartObj[item2b.id]) {
                  item.num = state.cartObj[item2b.id]
                 } else {
                  item.num = 0
                 }
                })*/
              }
            }
          })
        }
      } catch (err) {
        console.log('err---->>', err)
        Toast.clear()
        Notify({ type: 'danger', message: 'Server error' })
      }
    }
    // 貌似之前有个选择日期的控件 这是他的回调方法 被移除了吧
    const chooseConfirm = (item) => {
      state.deliveryDate = item.date
      getCateList()
    }
    const closeChoose = () => {
      state.chooseDateShow = false
    }
    const getUserSession = async() => {
      try {
        const res = await userInfo({ user_id: state.routeUserId })
        if (res.data.code) {
          Notify({ type: 'danger', message: res.data.message })
        } else {
          localStorage.setItem('loginInfo', JSON.stringify(res.data.data))
          setLocal('userId', res.data.data.user_id)
          setLocal('userName', res.data.data.name)
          sessionStorage.setItem('customer', '')
          handleLocalCart()
        }
      } catch (err) {
        Notify({ type: 'danger', message: 'Server error' })
      }
    }
    const getGoogleLocation = async() => {
      try {
        const res = await getCurrentPosition({ considerIp: 'true' })
        if (res.status === 200) {
          sessionStorage.setItem('position', JSON.stringify(res.data.location))
          state.currentPosition = res.data.location
          getOrderList()
        } else {
          getOrderList()
        }
      } catch (err) {
        getOrderList()
      }
    }
    const getGoodsListAll = async() => {
      const res = await getGoodsList({})
      if (res.data.code) {
        Notify({
          message: res.data.message,
          color: '#ad0000',
          background: '#ffe1e1'
        })
      } else {
        if (res.data.data) {
          const userId = getLocal('userId')
          if (userId) {
            await store.dispatch('updateCart')
            state.cartObj = store.state.cartGoods
          } else {
            store.dispatch('updateLocalCart')
            state.cartObj = store.state.cartGoods
          }
          await getTagList(res.data.data)
        }
      }
    }
    const getTagList = async(list) => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      const promise = list.map((item, i) => {
        // return
        var idArr = item.data
        var idStr = idArr.join(',')
        return getGoodsDetail(idStr, item.sale_name)
      })
      Promise.all(promise)
        .then((allData) => {
          console.log('getTagList--->allData', allData)
          allData.forEach((item, index) => {
            list[index].goodsList = item
          })
          state.tagsList = list
          Toast.clear()
        })
        .catch()
    }
    const getGoodsDetail = async(idStr, sale_time) => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      const res = await getMoreGoodsList({
        id_str: idStr,
        sale_time: ''
        // sale_time: sale_time
      })
      const goodList = res.data.data
      goodList.forEach((item) => {
        if (state.cartObj[item.product_stock_id]) {
          item.num = state.cartObj[item.product_stock_id]
        } else {
          item.num = 0
        }
      })
      Toast.clear()
      return goodList
    }
    const getOrderList = async() => {
      const res = await getNewOrderList({
        lat: state.currentPosition.lat,
        long: state.currentPosition.lng,
        num: 10
      })
      if (res.data.code) {
        Notify({
          message: res.data.message,
          color: '#ad0000',
          background: '#ffe1e1'
        })
      } else {
        state.orderList = res.data.data
        if (state.orderList && state.orderList.length) {
          if (state.orderList.length > 3) {
            nextTick(() => {
              roll(50)
            })
          }
        }
      }
    }
    const roll = (t) => {
      var ulbox = document.getElementById('review_box')
      ulbox.scrollTop = 0 // 开始无滚动时设为0
      state.orderTimer = setInterval(rollStart, t) // 设置定时器，参数t用在这为间隔时间（单位毫秒），参数t越小，滚动速度越快
      // 鼠标移入div时暂停滚动
      ulbox.onmouseover = function() {
        clearInterval(state.orderTimer)
      }
      // 鼠标移出div后继续滚动
      ulbox.onmouseout = function() {
        state.orderTimer = setInterval(rollStart, t)
      }
    }
    // 开始滚动函数
    const rollStart = () => {
      var ulbox = document.getElementById('review_box')
      // 正常滚动不断给scrollTop的值+1,当滚动高度大于列表内容高度时恢复为0
      var orderUl = document.getElementById('orderUl')
      if (ulbox.scrollTop >= orderUl.scrollHeight - 90) {
        ulbox.scrollTop = 0
      } else {
        ulbox.scrollTop++
      }
    }
    const getInformList = async() => {
      const res = await getNotice({})
      if (res.data.code) {
        Notify({
          message: res.data.message,
          color: '#ad0000',
          background: '#ffe1e1'
        })
      } else {
        var list = res.data.data
        var newList = []
        list.forEach((item) => {
          var link = ''
          if (item.indexOf('http') !== -1) {
            var str = item
            item = str.split('http')[0]
            link = 'http' + str.split('http')[1]
          }
          newList.push({
            name: item,
            link: link
          })
        })

        state.informList = newList
      }
    }
    const goToDetail = (id, stock_id) => {
      router.push({
        path: '/goodsDetail',
        query: { id: id, stock_id: stock_id }
      })
    }

    const plusChange = async(item, index) => {
      var stock_id = item.product_stock_id
      var goods_id = item.id
      var count = Number(item.num) + 1
      const userId = getLocal('userId')
      if (userId) {
        const res = await addCart({
          stock_id: stock_id,
          count: count
        })
        if (res.data.code) {
          if (res.data.code === 130048) {
            Dialog.confirm({
              title: 'Warning',
              message:
                'The delivery date of this product is different from other products in the shopping cart. Please clear or checkout shopping cart first.'
            })
              .then(async() => {
                router.push({ path: `/cart` })
              })
              .catch(() => {
                // on cancel
                item.num = 0
              })
          } else {
            item.num = 0
            Notify({
              message: res.data.message,
              color: '#ad0000',
              background: '#ffe1e1'
            })
          }
        }
        store.dispatch('updateCart')
      } else {
        const saveTemp = setLocalCart(
          goods_id,
          count,
          stock_id
        )
        // const saveTemp = setLocalCart(goods_id, count, stock_id, item.sale_time)
        if (saveTemp) {
          store.dispatch('updateLocalCart')
        } else {
          Dialog.confirm({
            title: 'Warning',
            message:
              'The delivery date of this product is different from other products in the shopping cart. Please clear or checkout shopping cart first.'
          })
            .then(async() => {
              router.push({ path: `/cart` })
            })
            .catch(() => {
              item.num = 0
            })
        }
      }
    }
    const minusChange = async(item, index) => {
      var goods_id = item.id
      var stock_id = item.product_stock_id
      var count = Number(item.num) - 1
      const userId = getLocal('userId')
      if (userId) {
        if (count > 0) {
          await updateCart({
            stock_id: stock_id,
            count: count
          })
        } else {
          await delCart({
            id_list: [stock_id]
          })
        }
        store.dispatch('updateCart')
      } else {
        if (count > 0) {
          setLocalCart(goods_id, count, stock_id)
        } else {
          delLocalCart(goods_id, stock_id)
        }
        store.dispatch('updateLocalCart')
      }
    }
    return {
      ...toRefs(state),
      count,
      goToDetail,
      plusChange,
      minusChange,
      getGoodsListAll,
      getOrderList,
      getInformList,
      getTagList,
      onClickLeft,
      chooseConfirm,
      closeChoose,
      getGoodsDetail,
      referFriend,
      dateList,
      getCateProductList,
      addConfirm
    }
  }
}
</script>
<style lang="less">
@import "../common/style/mixin";
.home {
  .goods-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .week_ul {
    flex-shrink: 0;
    width: 100%;
    padding: 0.2667rem;
    box-sizing: border-box;
    background: #fff;
  }

  .week_date {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .week_item {
      flex-shrink: 0;
      width: 1.7rem;
      height: 1.2rem;
      border-radius: 0.26rem;
      background: #fff;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0.1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #e5e5e5;
      span {
        font-size: 0.2rem;
        margin-bottom: 0.1rem;
        font-weight: bolder;
      }
      p {
        margin: 0;
        font-size: 0.4rem;
        font-weight: bolder;
      }
    }
  }
  .check_date {
    background: @primary !important;
    color: #fff !important;
  }

  .good-list-scroll {
    flex: 1;
    width: 100%;
    padding-bottom: 10px;
    background: #FFFFFF;
    overflow: hidden auto;
  }

  .van-nav-bar .van-icon {
    color: @primary !important;
  }
  .van-nav-bar .title_left {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .van-icon {
      font-size: 20px;
      margin-right: 4px;
    }
    .choose_date {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      font-size: 0.32rem;
      padding: 0.1333rem 0;
      height: 100%;
      box-sizing: border-box;
      p {
        margin: 0;
      }
      .deliver_date {
        font-weight: bolder;
        font-size: 0.34rem;
      }
    }
  }
  .share_part {
    width: 100%;
    height: 2.6667rem;
    background: rgba(29, 149, 63, 0.1) url("./../assets/img/gift.png") no-repeat
      right bottom;
    background-position-x: 95%;
    background-position-y: 70%;
    background-size: 15%;
    padding: 0.4rem;
    box-sizing: border-box;
    margin-top: 0.266667rem;
    position: relative;
    .point_bg {
      display: block;
      width: 0.8rem;
      height: 0.8rem;
      background: url("./../assets/img/point.png") no-repeat left bottom;
      background-size: 100%;
      position: absolute;
    }
    p {
      margin: 0;
    }
    .get_point {
      box-sizing: border-box;
      margin-left: 1.3333rem;
      font-size: 0.5067rem;
      padding-top: 0.1333rem;
      font-weight: bolder;
      color: @primary;
    }
    .point_title {
      width: 100%;
      font-size: 0.4267rem;
      margin-top: 0.26rem;
      color: @primary;
    }
    .share_btn {
      padding: 0.1rem 0.3rem;
      background: #fff;
      border-radius: 20px;
      position: absolute;
      font-size: 0.3733rem;
      font-weight: 500;
      text-align: center;
      right: 15%;
      top: 0.5333rem;
      color: @primary;
    }
  }
  .title_info {
    width: 100%;
    background: #fff;
    font-size: 0.4rem;
    font-weight: 500;
    color: #2c3e50;
    box-sizing: border-box;
    padding: 0.3rem 0.2rem 0.6rem;
    background: #fff url("./../assets/img/sun.png") no-repeat right bottom;
    background-size: 20%;
    p {
      margin: 0;
      height: 0.8rem;
      line-height: 0.8rem;
      color: #000;
    }
  }
  .content {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 96px);
    margin-bottom: 1.6rem;
  }
  .cate_arr {
    margin-bottom: 1.2rem;
  }
  .my-swipe {
    width: 100%;
    height: 30vh;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #8abcdb;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .home_title {
    width: 100%;
    height: 1.066667rem /* 80/75 */;
    line-height: 1.066667rem /* 80/75 */;
    color: #333;
    font-size: 0.48rem /* 36/75 */;
    border-bottom: 1px solid #eeeeee;
    font-weight: 500;
  }
  .home_time {
    display: flex;
    flex-direction: row;
    padding: 0 0.4rem;
    box-sizing: border-box;
    // background: rgba(29,149,63, 0.1);
    background: #f6f6f6;
    border-bottom: 1px solid #eee;
    color: #333;
    span:nth-child(1) {
      color: #666;
      font-size: 0.38rem;
      margin-right: 0.1333rem;
    }
    .week {
      margin-left: 0.1333rem;
    }
  }
  .inform {
    .notice-swipe {
      height: 1.066667rem /* 80/75 */;
      line-height: 1.066667rem /* 80/75 */;
    }
  }
  .notice_info {
    width: 100%;
    background: #fff;
    padding: 0 0.4rem;
    box-sizing: border-box;
    margin-top: 0.266667rem;
    .notice_list {
      padding: 0.1rem 0;
    }
    p {
      line-height: 0.6rem;
      color: #323233;
      font-size: 0.3733rem;
      font-weight: 700;
      margin: 0;
      .go_group {
        // color: rgb(162, 0, 255);
        color: royalblue;
        font-weight: bolder;
        border-bottom: 1px solid royalblue;
      }
    }
  }
  .new_orders {
    margin-top: 0.266667rem /* 20/75 */;
    background: #fff;
    padding: 0 0.4rem /* 30/75 */;
    box-sizing: border-box;
    width: 100%;
    max-height: 4rem /* 300/75 */;
    overflow: hidden;
    margin-bottom: 0.2667rem;
    .order_scroll {
      width: 100%;
      height: 110px;
      padding: 10px /* 20/75 */ 0;
      box-sizing: border-box;
      overflow: hidden;
    }
    .order_list {
      li {
        height: 30px /* 60/75 */;
        line-height: 30px /* 60/75 */;
        font-size: 0.373333rem /* 28/75 */;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
  .cate_list {
    margin-top: 0.266667rem /* 20/75 */;
    background: #fff;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
  }
  .goods_list {
    padding: 0 0.4rem;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    .right {
      .tagBox {
        display: flex;
        flex-wrap: wrap;
        span {
          margin-right: 5px;
        }
      }
    }
    .list_first_showdate {
      font-size: 16px;
      font-weight: bolder;
      background: royalblue;
      text-align: center;
      padding: 8px;
      color: #fff;
      border-radius: 8px;
      margin-bottom: 8px;
    }
  }
  .van-tabs__nav--line {
    padding-bottom: 8px;
  }
}
</style>
