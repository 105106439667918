/*
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:27:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\router\index.js
 */
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { keepAlive: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Cart.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Order.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Mine.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/cardBag',
    name: 'CardBag',
    component: () => import(/* webpackChunkName: "about" */ '@/views/CardBag.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/goodsDetail',
    name: 'Product',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Product.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/orderDetail',
    name: 'OrderDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/OrderDetail.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/addressList',
    name: 'AddressList',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AddressList.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/submitOrder',
    name: 'SubmitOrder',
    component: () => import(/* webpackChunkName: "about" */ '@/views/SubmitOrder.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/addAddress',
    name: 'AddAddress',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AddAddress.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/evaluatePage',
    name: 'EvaluatePage',
    component: () => import(/* webpackChunkName: "about" */ '@/views/EvaluatePage.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/orderShare',
    name: 'OrderShare',
    component: () => import(/* webpackChunkName: "about" */ '@/views/OrderShare.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/registerShare',
    name: 'RegisterShare',
    component: () => import(/* webpackChunkName: "about" */ '@/views/RegisterShare.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/pointsList',
    name: 'PointsList',
    component: () => import(/* webpackChunkName: "about" */ '@/views/PointsList.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/messageList',
    name: 'MessageList',
    component: () => import(/* webpackChunkName: "about" */ '@/views/MessageList.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/editOrder',
    name: 'EditOrder',
    component: () => import(/* webpackChunkName: "about" */ '@/views/EditOrder.vue'),
    meta: { keepAlive: false }
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ContactUs.vue'),
    meta: { keepAlive: false }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
