/*
 * @Author: your name
 * @Date: 2021-04-21 10:33:06
 * @LastEditTime: 2021-04-21 10:39:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\service\user.js
 */

import axios from '../utils/axios'

// 获取验证码接口
export function getsms(params) {
  return axios.post('/user/verify', params)
}
// 登录
export function login(params) {
  return axios.post('/user/login', params)
}

// js认证
export function jsAuth(params) {
  return axios.post('/api/js/auth', params)
}

// 微信登录
export function wxLogin(params) {
  return axios.post('/api/auth/parseCode', params)
}

// 获取地址簿列表
export function getAddressList(params) {
  return axios.get('/api/address/list', { params })
}
// 添加地址
export function addAddress(params) {
  return axios.post('/api/address/add', params)
}
// 编辑地址
export function editAddress(params) {
  return axios.post('/api/address/update', params)
}
// 删除地址
export function DelAddress(params) {
  return axios.post('/api/address/del', params)
}
// 获取卡列表
export function getCardList(params) {
  return axios.get('/api/address/list', { params })
}
// 获取用户当前位置
export function getCurrentPosition(params) {
  return axios.post('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDR0LyFODkJ8CS3B8bGO_YoCzK3C279O90', params)
}
// 获取订单列表
export function getOrder(params) {
  return axios.get('/api/user/order', { params })
}
// 获取订单详情
export function getOrderDetail(params) {
  return axios.get('/api/order/get', { params })
}
// 创建订单
export function createOrder(params) {
  return axios.post('/api/order/create', params)
}
// 创建支付
export function createPay(params) {
  return axios.post('/api/pay/create', params)
}
// 修改订单状态
export function setOrderStatus(params) {
  return axios.post('/api/order/set', params)
}
export function goodleLogin(params) {
  return axios.get('/api/GoogleLogin', { params })
}
// 获取用户信息
export function userInfo(params) {
  return axios.get('/api/getToken', { params })
}
// 获取区域列表
export function areaList(params) {
  return axios.get('/api/delivery_area/list', { params })
}
// 上传图片
export function uploadImg(params) {
  var uploadFile = {
    uploadFile: true
  }
  return axios.post('/api/file/upload', params, uploadFile)
}
// 评价商品
export function setEvaluate(params) {
  return axios.post('/api/order_product/appraise', params)
}
// 点赞评价
export function likeEvaluate(params) {
  return axios.get('/api/order_product_appraise/like', { params })
}
// 取消评价
export function cancelLike(params) {
  return axios.get('/api/order_product_appraise/cancel_like', { params })
}
// 获取评价状态
export function getLikeStatus(params) {
  return axios.get('/api/order_product_appraise/like_status', { params })
}
// 获取评价详情
export function evaluateDetail(params) {
  return axios.get('/api/order_product_appraise/appraise_list_order', { params })
}
// 修改订单中的地址
export function editOrderAddress(params) {
  return axios.post('/api/order/update', params)
}
export function applyRefund(params) {
  return axios.post('/api/order/refund', params)
}
// 根据邮编获取配送费
export function zipCodeSearch(params) {
  return axios.get('/api/delivery_area/search', { params })
}
// 显示在订单中的满减条件
export function getFee(params) {
  return axios.get('/api/fee/get', { params })
}
